import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import { AuthProvider } from '../src/context/AuthProvider';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
      <AuthProvider>
        <App /> 
      </AuthProvider>
    {/* </BrowserRouter> */}
  </React.StrictMode>
);

reportWebVitals();
